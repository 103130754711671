'use client';

import Image, { StaticImageData } from 'next/image';
import React from 'react';

import BannerCardCart from '../../../../../public/assets/banner-cards/banner_cards-cart.webp';
import BannerCardClock from '../../../../../public/assets/banner-cards/banner_cards-clock.webp';
import BannerCardClockBlue from '../../../../../public/assets/banner-cards/banner_cards-clock-blue.webp';
import BannerCardClockYellow from '../../../../../public/assets/banner-cards/banner_cards-clock-yellow.webp';
import BannerCardGear from '../../../../../public/assets/banner-cards/banner_cards-gear.webp';
import BannerCardGift from '../../../../../public/assets/banner-cards/banner_cards-gift.webp';
import BannerCardHand from '../../../../../public/assets/banner-cards/banner_cards-hand.webp';
import BannerCardHandYellow from '../../../../../public/assets/banner-cards/banner_cards-hand-yellow.webp';
import BannerCardHeart from '../../../../../public/assets/banner-cards/banner_cards-heart.webp';
import BannerCardLock from '../../../../../public/assets/banner-cards/banner_cards-lock.webp';
import BannerCardLockGreen from '../../../../../public/assets/banner-cards/banner_cards-lock-green.webp';
import BannerCardMail from '../../../../../public/assets/banner-cards/banner_cards-mail.webp';
import BannerCardSquareQuestion from '../../../../../public/assets/banner-cards/banner_cards-square-question.webp';
import BannerCardTicketSale from '../../../../../public/assets/banner-cards/banner_cards-ticket-sale.webp';
import BannerCardUser from '../../../../../public/assets/banner-cards/banner_cards-user.webp';
import CategoryDiscount from '../../../../../public/assets/categories/illustration_categories-discount.webp';
import CategoryEggs from '../../../../../public/assets/categories/illustration_categories-eggs.webp';
import CategoryExclusive from '../../../../../public/assets/categories/illustration_categories-exclusive.webp';
import CategoryMM2Bundles from '../../../../../public/assets/categories/illustration_categories-mm2-bundles.webp';
import CategoryMM2Discount from '../../../../../public/assets/categories/illustration_categories-mm2-discount.webp';
import CategoryMM2Exclusive from '../../../../../public/assets/categories/illustration_categories-mm2-exclusive.webp';
import CategoryMM2Guns from '../../../../../public/assets/categories/illustration_categories-mm2-guns.webp';
import CategoryMM2Knives from '../../../../../public/assets/categories/illustration_categories-mm2-knives.webp';
import CategoryMM2New from '../../../../../public/assets/categories/illustration_categories-mm2-new.webp';
import CategoryMM2Pets from '../../../../../public/assets/categories/illustration_categories-mm2-pets.webp';
import CategoryMM2Under from '../../../../../public/assets/categories/illustration_categories-mm2-under.webp';
import CategoryNew from '../../../../../public/assets/categories/illustration_categories-new.webp';
import CategoryPets from '../../../../../public/assets/categories/illustration_categories-pets.webp';
import CategoryPotions from '../../../../../public/assets/categories/illustration_categories-potions.webp';
import CategoryTransport from '../../../../../public/assets/categories/illustration_categories-transport.webp';
import CategoryUnder from '../../../../../public/assets/categories/illustration_categories-under.webp';
import NoItem from '../../../../../public/assets/items/no-item.webp';
import {
  BannerCardItemVariant,
  CategoryItemVariant,
  ItemProps,
} from '../item.types';

const ItemSize = {
  '218': 218,
  '152': 152,
  '124': 124,
  '96': 96,
  '92': 92,
  '90': 90,
  '68': 68,
  '60': 60,
  '52': 52,
  '48': 48,
  '42': 42,
} as const;

export const ItemImageByVariant = <T,>({
  size,
  src,
  className,
  style,
}: {
  src: string | undefined;
  size: keyof typeof ItemSize;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <Image
      draggable={'false'}
      fetchPriority={'high'}
      priority={true}
      style={style}
      loading={'eager'}
      className={className}
      width={ItemSize[size]}
      height={ItemSize[size]}
      src={src ?? NoItem.src}
      quality={75}
      alt={`${src} item`}
    />
  );
};

export const CategoryItemImageByVariant = ({
  size,
  variant,
  className,
  srcPrefix = '',
  style,
  srcExtension = 'webp',
  ...props
}: ItemProps<CategoryItemVariant>) => {
  const ItemImage: { [key: string]: StaticImageData } = {
    new: CategoryNew,
    under: CategoryUnder,
    discount: CategoryDiscount,
    pets: CategoryPets,
    eggs: CategoryEggs,
    transport: CategoryTransport,
    exclusive: CategoryExclusive,
    potions: CategoryPotions,

    'mm2-under': CategoryMM2Under,
    'mm2-discount': CategoryMM2Discount,
    'mm2-exclusive': CategoryMM2Exclusive,
    'mm2-guns': CategoryMM2Guns,
    'mm2-knives': CategoryMM2Knives,
    'mm2-new': CategoryMM2New,
    'mm2-pets': CategoryMM2Pets,
    'mm2-bundles': CategoryMM2Bundles,
  };

  return (
    <Image
      fetchPriority={'high'}
      //priority={true}
      style={style}
      loading={'lazy'}
      className={className}
      width={ItemSize[size]}
      height={ItemSize[size]}
      src={ItemImage[variant as keyof typeof ItemImage].src}
      quality={100}
      alt={`${variant} item`}
    />
  );
};

export const BannerCardItemImageByVariant = ({
  size,
  variant,
  className,
  srcPrefix = '',
  style,
  srcExtension = 'webp',
  ...props
}: ItemProps<BannerCardItemVariant>) => {
  const ItemImage: { [key: string]: StaticImageData } = {
    hand: BannerCardHand,
    'hand-yellow': BannerCardHandYellow,
    lock: BannerCardLock,
    'lock-green': BannerCardLockGreen,
    'clock-yellow': BannerCardClockYellow,
    'clock-blue': BannerCardClockBlue,
    mail: BannerCardMail,
    gear: BannerCardGear,
    user: BannerCardUser,
    gift: BannerCardGift,
    clock: BannerCardClock,
    cart: BannerCardCart,
    'ticket-sale': BannerCardTicketSale,
    heart: BannerCardHeart,
    'square-question': BannerCardSquareQuestion,
  };

  return (
    <Image
      fetchPriority={'high'}
      priority={true}
      style={style}
      loading={'eager'}
      className={className}
      width={ItemSize[size]}
      height={ItemSize[size]}
      src={ItemImage[variant as keyof typeof ItemImage].src}
      quality={90}
      alt={`${variant} item`}
    />
  );
};
