import cn from 'clsx';

import { Illustration } from '@/shared/ui';

import styles from './item-rarity-item-page.module.scss';
import { ItemRarityItemPageIconSvgProps } from './item-rarity-item-page.types';

export const colorsByVariant = {
  common: 'var(--rarity-common-background100)',
  uncommon: 'var(--rarity-uncommon-background100)',
  ultra_rare: 'var(--rarity-ultra-rare-background100)',
  rare: 'var(--rarity-rare-background100)',
  legendary: 'var(--rarity-legendary-background100)',
} as const;

export const colorsMM2Variant = {
  common: 'var(--rarity-legendary-background100)',
  uncommon: 'var(--rarity-common-background100)',
  unique: 'var(--rarity-uncommon-background100)',
  vintage: 'var(--rarity-ultra-rare-background100)',
  rare: 'var(--rarity-rare-background100)',
  godly: '#FE9920',
  ancient: '#E0E345',
  legendary: '#D41057',
} as const;

export const ItemRarityItemPage = ({
  variant,
  className,
  style,
  isDesktop,
  colorScheme,
  isMM2Background,
  ...props
}: ItemRarityItemPageIconSvgProps) => {
  const itemRarityDesktopClasses = cn(styles['item-rarity'], className);
  const itemRarityMobileClasses = cn(styles['item-rarity-mobile'], className);
  const nameDesktop = isMM2Background
    ? 'item-rarity-item-mm2-page-1'
    : 'item-rarity-item-page-1';

  const nameMobile = isMM2Background
    ? 'item-rarity-item-mm2-page-mobile-1'
    : 'item-rarity-item-page-mobile-1';

  return (
    <Illustration
      style={{ color: colorScheme[variant], ...style }}
      spanTagClassName={
        isDesktop ? itemRarityDesktopClasses : itemRarityMobileClasses
      }
      name={isDesktop ? nameDesktop : nameMobile}
    />
  );
};
