import 'react-loading-skeleton/dist/skeleton.css';

import cn from 'clsx';
import SkeletonUi from 'react-loading-skeleton';

import { SkeletonProps } from '@/shared/ui/skeleton/skeleton.types';

import styles from './skeleton.module.scss';

export const Skeleton = ({
  containerClassName,
  virtualized,
  ...props
}: SkeletonProps) => {
  const skeletonContainerClasses = cn(
    styles['skeleton-container'],
    containerClassName,
  );

  if (virtualized) {
    return (
      <div
        id={'skeleton'}
        style={{ width: 'max-content', height: 'max-content' }}
      >
        <SkeletonUi
          baseColor={'#F6F6F6'}
          highlightColor={'#EFEFEF'}
          containerClassName={skeletonContainerClasses}
          {...props}
        />
      </div>
    );
  }

  return (
    <SkeletonUi
      baseColor={'#F6F6F6'}
      highlightColor={'#EFEFEF'}
      containerClassName={skeletonContainerClasses}
      {...props}
    />
  );
};
