import cn from 'clsx';

import { Typography } from '@/shared/ui';

import styles from './tag-quantity.module.scss';
import { TagQuantityProps } from './tag-quantity.types';
export const TagQuantity = ({ quantity = 0, className }: TagQuantityProps) => {
  const tagQuantityClasses = cn(
    styles['container'],
    {
      [styles['container--default']]: quantity <= 0,
      [styles['container--squared']]: quantity < 10,
    },
    className,
  );

  return (
    <div className={tagQuantityClasses}>
      <Typography
        className={
          quantity <= 0
            ? styles['quantity-value--none']
            : styles['quantity-value']
        }
        size={'s'}
        weight={'bold'}
        as={'span'}
      >
        {quantity}
      </Typography>
    </div>
  );
};
