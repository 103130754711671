import React from 'react';

import {
  ItemProps,
  ItemSize,
  ItemVariant,
  ItemVariantMM2,
} from '@/shared/ui/item/item.types';

import { ItemImageByVariant } from './item-image-by-variant/item-image-by-variant';
export const Item = ({
  className,
  src,
  size,
  style,
}: {
  src: string | undefined;
  size: ItemSize;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <ItemImageByVariant
      style={style}
      className={className}
      src={src}
      size={size}
    />
  );
};
