import { motion } from 'framer-motion';
import React from 'react';

import { MotionWrapperProps } from '@/shared/ui/desktop/motion-wrapper/types';

export const MotionWrapper: React.FC<MotionWrapperProps> = ({
  children,
  className,
  onClick,
}) => {
  const animations = {
    initial: { scale: 0 },
    animate: { scale: 1 },
    exit: { scale: 0 },
  };

  return (
    <motion.div {...animations} className={className} onClick={onClick}>
      {children}
    </motion.div>
  );
};
