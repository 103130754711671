import cn from 'clsx';

import { Illustration } from '@/shared/ui';

import styles from './tag-category.module.scss';
import { TagCategoryIconSvgProps } from './tag-category.types';

export const TagCategory = ({
  size,
  className,
  variant,
  id,
}: TagCategoryIconSvgProps) => {
  const tagCategoryClasses = cn(
    styles['size'],
    styles[`size--${size}`],
    className,
  );

  return (
    <Illustration
      id={id}
      spanTagClassName={tagCategoryClasses}
      spriteName={'icons'}
      name={`tag-category-${variant}`}
    />
  );
};
