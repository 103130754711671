import cn from 'clsx';
import React from 'react';

import { ModalDesktop } from '@/shared/ui/desktop/modal-desktop';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';

import styles from './modal-wrapper.module.scss';
import { ModalProps } from './types';

export const ModalWrapper = ({
  isOpened,
  isMobile,
  close,
  children,
  className,
  hideBottomSheet = false,
}: ModalProps) => {
  if (isMobile && !hideBottomSheet) {
    return (
      <BottomSheetUi
        handleClose={close}
        isOpen={isOpened}
        className={styles['mobile-modal']}
      >
        {children}
      </BottomSheetUi>
    );
  }
  return (
    <ModalDesktop
      handleClose={close}
      isOpened={isOpened}
      className={cn(styles['desktop-modal'], className)}
    >
      {children}
    </ModalDesktop>
  );
};
